<template>
  <Transition :name="animation">
    <div
        :class="[
    'flex flex-row h-auto w-full items-center relative bg-white py-0.5',
    getAppropriateClass,
    isRounded ? 'rounded' : '',
  ]"
        v-if="visibility"
    >
      <div class="flex flex-col w-11/12">
        <!-- Error Heading Starts -->
        <div
            :class="[
        'flex flex-row w-full items-center',
        getAppropriateAlignmentClass,
      ]"
        >
          <Icon
              class="w-2/12 text-xl md:text-2xl px-2"
              :name="getAppropriateIconClass
                "
          />
          <p
              :class="[
          'w-9/12 text-sm md:text-base font-semibold text-center',
        ]"
          >
            {{ alert.message }}
          </p>
          <Icon
              v-if="isError"
              :name="viewDetails
                ? 'tabler:chevron-up'
                : 'tabler:chevron-down'
                "
              class="w-1/12 cursor-pointer underline text-xl md:text-2xl"
              @click="
                viewDetails = !viewDetails
                "
          >
          </Icon>
        </div>
        <!-- Error Heading Ends -->
        <div v-show="viewDetails">
          <div
              v-if="isError"
              :class="[
               'flex flex-row w-full items-center',
               getAppropriateAlignmentClass,
             ]"
          >
          <span :class="['text-sm mx-2']">
            {{ alert.errorType }} -
            {{ alert.errorCode }} -
            {{ alert.detail }}</span>
          </div>
          <div
              v-if="alert.validationErrorsBag &&
          Object.keys(
            alert.validationErrorsBag
          ).length > 0
          "
          >
            <div
                class="flex flex-col text-xs my-0.5"
                v-for="errorKey in Object.keys(
                 alert.validationErrorsBag
               )"
                :key="errorKey"
            >
              <p class="flex items-center justify-start">
                <Icon
                    name="fa6-solid:circle-dot"
                    class="text-xs mx-1"
                />
                {{ errorKey }}
              </p>
              <span
                  v-for="errorMessage in alert
              .validationErrorsBag[
              errorKey
            ]"
                  :key="errorMessage"
                  class="ml-5"
              >
              {{ errorMessage }}
            </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row w-1/12 items-start justify-center">
        <!-- Alert Dismiss Button Starts -->
        <button
            type="button"
            class="outline-none"
            @click="dismissAlert()"
        >
          <Icon
              name="mdi:close-box"
              class="text-3xl p-1"
          />
        </button>
        <!-- Alert Dismiss Button Ends -->
      </div>
    </div>
  </Transition>
</template>

<script setup>
const alertStore = useAlert();

const props = defineProps({
  alignText: {
    type: [String],
    required: false,
    default: "left",
  },
  animation: {
    type: String,
    required: false,
    default: "fade",
  },
  autoDismissible: {
    type: Boolean,
    required: false,
    default: true,
  },
  isRounded: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const viewDetails = ref(false);

const errorClasses = ref(
    "text-a-error-dark border-2 border-a-error"
);
const successClasses = ref(
    "text-a-success border-2 border-a-success"
);
const type = computed(() => {
  return alertStore.alert.type;
});

const isError = computed(() => {
  return alertStore.alert.type === "Error";
});

const isSuccess = computed(() => {
  return alertStore.alert.type === "Success";
});

const alert = computed(() => {
  return alertStore.alert;
});

const visibility = computed(() => {
  return alertStore.visibility;
});

const validationErrorsBag = computed(
    () => {
      return alertStore.alert
          .validationErrorsBag;
    }
);

const getAppropriateClass = computed(
    () => {
      let classes = [];
      if (isError.value) {
        classes = errorClasses.value;
      } else if (isSuccess.value) {
        classes = successClasses.value;
      } else {
        classes = ["bg-a-secondary"];
      }
      return classes;
    }
);

const getAppropriateIconClass =
    computed(() => {
      if (isError.value) {
        return "material-symbols:error-circle-rounded-outline-sharp";
      } else if (isSuccess.value) {
        return "ion:md-checkmark-circle-outline";
      } else {
        return "mdi:information-variant-circle-outline";
      }
    });

const getAppropriateAlignmentClass =
    computed(() => {
      let classes = [];
      if (
          props.alignText.value === "center"
      ) {
        classes = ["justify-center"];
      } else if (
          props.alignText.value === "right"
      ) {
        classes = ["justify-end"];
      } else {
        classes = ["justify-start"];
      }
      return classes;
    });

function dismissAlert() {
  alertStore.dismissAlert();
}

onMounted(() => {
  if (props.autoDismissible.value) {
    setTimeout(
        this.dismissAlert(),
        5000
    );
  }
});
</script>

<style scoped>
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: all 0.5s ease-out;
}

.slidedown-enter {
  /* opacity: 0; */
  transform: translateY(-100%);
}

.slidedown-enter-active {
  transition: all 0.3s ease;
}

.slidedown-leave-to {
  /* opacity: 0; */
  transform: translateY(-100%);
}

.slidedown-leave-active {
  transition: all 0.3s ease;
}
</style>
